import React from 'react'
import TechLibraryFiles from '../components/pdf/tech-library-file'
import FullScreenPdf from '../components/pdf/fullscreen-pdf'
import { Router as MyRouter } from '@reach/router'
import PublicationCorrectionPdf from '../components/pdf/publication-correction-pdf'

const Router = () => {
  return (
    <MyRouter>
      <TechLibraryFiles path="/files/tech-library/:url" />
      <FullScreenPdf path="/files/quick-references/:url" />
      <PublicationCorrectionPdf path="/files/pubication-corrections/:url" />
    </MyRouter>
  )
}

export default Router
