import React from 'react'
import Loader from '../ui-components/Loader'
import { Container } from 'starterUiComponents'
import { Alert, AlertIcon, chakra } from '@chakra-ui/react'

const bucket = process.env.GATSBY_FIREBASE_STORAGE_BUCKET

const TechLibraryFiles = (props) => {
    const { url } = props
    const [pdfLoading, setPdfLoading] = React.useState(true)
    const [pdfIsSave, setPdfIsSave] = React.useState(false)

    const iFrameLoaded = (e) => {
        setPdfLoading(false)
    }

    const fetchPdfURL = (getPdfUrl) => {
        fetch(getPdfUrl, {})
            .then((response) => response.json())
            .then((data) => {
                if (data.error && data.error.code) {
                    // for firebase errors
                    setPdfIsSave(false)
                    setPdfLoading(false)
                } else {
                    setPdfIsSave(true)
                }
            })
            .catch((err) => {
                setPdfIsSave(true)
                setPdfLoading(false)
            })
    }

    const getPdfUrl = (url) => {
        url = `pdfs/tech-library/${url}`.replaceAll('/', '%2F')
        const BASE_URL = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/`
        const urlFinal = `${BASE_URL}${url}?alt=media&token=123`
        return urlFinal
    }
    const pdfUrl = getPdfUrl(url)

    React.useEffect(() => {
        fetchPdfURL(pdfUrl)
    }, [url])

    return (
        <div
            className="p-0 m-0 text-white w-full min-h-full"
            style={{
                backgroundColor: '#525659',
                position: 'relative',
                top: '0px',
                bottom: '0px',
            }}
        >
            {pdfLoading && <Loader message="Loading" color="white" />}
            {pdfIsSave && (
                <iframe
                    title="tech-library-file"
                    src={pdfUrl}
                    style={{
                        position: 'fixed',
                        top: '0px',
                        bottom: '0px',
                        right: '0px',
                        width: '100%',
                        border: 'none',
                        margin: '0',
                        padding: '0',
                        overflow: 'hidden',
                        zIndex: '999999',
                        height: '100%',
                    }}
                    frameBorder="0"
                    onLoad={iFrameLoaded}
                    onError={(error) => console.log('>>> error: ', error)}
                ></iframe>
            )}
            {!pdfLoading && !pdfIsSave && (
                <Container size="sm">
                    <chakra.h2 className="mb-10 text-center uppercase text-l2">
                        Error loading PDF
                    </chakra.h2>
                    <Alert status="error">
                        <AlertIcon className="text-white" />
                        <p className="text-white">
                            Sorry for any inconvenience. Please contact support so we can help
                            you fix this issue.
                        </p>
                    </Alert>
                </Container>
            )}
        </div>
    )
    // }
}

export default TechLibraryFiles