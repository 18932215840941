import React from 'react'
import Loader from '../ui-components/Loader'

const PublicationCorrectionPdf = (props) => {
  const { url } = props
  const [pdfLoading, setPdfLoading] = React.useState(true)

  const pdfUrl = `${process.env.GATSBY_WP_URL}/publication-corrections/${url}/`

  const iFrameLoaded = (e) => {
    setPdfLoading(false)
  }

  return (
    <div
      className="p-0 m-0 text-white w-full min-h-full"
      style={{
        backgroundColor: '#525659',
        position: 'relative',
        top: '0px',
        bottom: '0px',
      }}
    >
      {pdfLoading && <Loader message="Loading pdf" />}
      <iframe
        id="my-iframe"
        title="tech-library-file"
        onLoad={iFrameLoaded}
        onError={(error) => console.log('>>> error: ', error)}
        src={pdfUrl}
        style={{
          width: '100%',
          minHeight: '100%',
        }}
      />
    </div>
  )
}

export default PublicationCorrectionPdf
